import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../../Header";
import { Fade } from "react-reveal";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function Pharma() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>
     
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Pharma.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>Pharma</h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          The pharmaceutical trading industry stands to benefit significantly
          from technological presence and advancements, contributing to enhanced
          efficiency, transparency, and overall growth.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Inventory and Logistics Management
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>
            Technowell has established a significant presence in providing
            innovative solutions for Inventory and Logistics Management tailored
            specifically for the pharmaceutical industry. Our expertise in this
            domain is driven by a commitment to enhancing operational
            efficiency, ensuring regulatory compliance, and optimizing the
            supply chain.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            By leveraging our advanced systems, Dhanvi Exim has experienced
            enhanced operational efficiency, improved inventory control, and
            streamlined logistics processes, contributing to their overall
            business success.
          </p>
          {/* <button
            style={{
              border: "2px solid #155f82",
              color: "#155f82",
              width: "150px",
              borderRadius: "50px",
              padding: "10px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "center",
              boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            }}
          >
            Learn more
            <img
              src="/img/right-arrow copy.svg"
              style={{ width: 20, marginLeft: "10px" }}
            />
          </button> */}
        </div>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

import React, { useState } from "react";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const TechnoBI = () => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <img
        src="/img/imagesrc/Techno Bi inside.jpeg"
        alt="Techno bi"
        style={imageStyle}
      />
      <div style={{ width: "100%", textAlign: "center" }}>
        <h2
          style={{ fontFamily: "Poppins", fontWeight: 700, color: "#155f82" }}
        >
          Techno BI
        </h2>
      </div>
      <div style={containerStyle}>
        <div style={headerStyle}>
          <h1 style={titleStyle}>
            Introducing Techno BI: Your Data Visualization and Reporting
            Powerhouse
          </h1>

          <p style={contentStyle}>
            Techno BI is a revolutionary AI and machine learning-powered tool
            that empowers you to transform your data into stunning visuals and
            insightful reports. Whether you're an enterprise or an individual
            user, Techno BI provides you with the flexibility and scalability
            you need to make informed decisions and gain valuable insights from
            your data.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Connect to Various Databases and Unleash Your Data's Potential
          </h2>
          <p style={contentStyle}>
            Techno BI seamlessly connects to various databases, allowing you to
            consolidate your data into a single, unified platform. This
            eliminates the need to juggle multiple tools and spreadsheets,
            saving you time and effort.
          </p>
        </div>
        <div style={sectionStyle}>
          <h4 style={{ fontFamily: "Poppins" }}>
            Collaborative Visualization and Reporting
          </h4>
          <p style={contentStyle}>
            Techno BI seamlessly connects to various databases, allowing you to
            consolidate your data into a single, unified platform. This
            eliminates the need to juggle multiple tools and spreadsheets,
            saving you time and effort.
          </p>
        </div>
        <div style={sectionStyle}>
          <h4 style={{ fontFamily: "Poppins" }}>
            Collaborative Visualization and Reporting
          </h4>
          <p style={contentStyle}>
            With Techno BI, multiple users can simultaneously create and share
            dashboards, visualizations, and reports. This fosters collaboration
            and ensures everyone is on the same page. You can even favorite
            dashboards for quick access to your most important data.
          </p>
        </div>
        <div style={sectionStyle}>
          <h4 style={{ fontFamily: "Poppins" }}>
            Explore the Power of Geo-Spatial Integration{" "}
          </h4>
          <p style={contentStyle}>
            Techno BI is at the forefront of innovation, integrating
            cutting-edge Geo-Spatial Services with its machine learning and data
            mining capabilities. This allows you to visualize your data on maps,
            gaining deeper insights into geographical trends and patterns.
          </p>
        </div>
        <div style={sectionStyle}>
          <h4 style={{ fontFamily: "Poppins" }}>
            Available as a Service and Soon as a Product{" "}
          </h4>
          <p style={contentStyle}>
            Currently available as a service, Techno BI will soon be released as
            a product, making it even more accessible to individual users. This
            means you'll have the power of enterprise-grade data visualization
            and reporting at your fingertips, regardless of your budget or
            technical expertise.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Key Features and Benefits:</h2>
          <ul style={listStyle}>
            <li>
              <b>Administrator Management :</b> Control key system areas like
              administrative units, drop-down lists, and data entry questions.
            </li>
            <li>
              <b>User Management and Access Control :</b> Manage
              users, groups, and access permissions to ensure data security and
              privacy.
            </li>
            <li>
              <b>Real-Time Updates and Remote Access :</b> Enjoy real-time
              updates on your dashboards and access the system from
              anywhere, even in remote areas.
            </li>
            <li>
              <b>Secure and Dynamic Data Management :</b> Enter data using
              user-friendly forms, edit within set timeframes, and ensure
              high-level data security and integrity.{" "}
            </li>
            <li>
              <b>Easy Data Auditing :</b> Track who accesses what
              data, when, and why for complete transparency and accountability.{" "}
            </li>
            <li>
              <b>Seamless Data Updates :</b> Update records annually with
              historical record storage and time-bound update periods.{" "}
            </li>
            <li>
              <b>Customizable Reports :</b> Generate real-time, annual, and
              trend analysis reports tailored to your specific needs and access
              level.{" "}
            </li>
            <li>
              <b>Flexible Report Formats :</b> Present your data in various
              formats, including tables, graphs, and maps, for clear and
              impactful communication.
            </li>
            <li>
              <b> Data Extraction :</b> Export your data in multiple
              formats, including Excel, PDF, and images, for further analysis or
              sharing.{" "}
            </li>
            <li>
              <b> Informative Dashboards :</b> Gain quick insights with
              user-friendly dashboards that summarize key information based on
              your permissions.{" "}
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Get Started with Techno BI Today!</h2>
          <p style={contentStyle}>
            Are you ready to unlock the power of your data and gain valuable
            insights that drive better decision-making? Contact us today to
            learn more about Techno BI and how it can help you achieve your
            goals.
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "#1f5d7d",
          marginRight: "auto",
          marginLeft: "auto",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "5px",
        }}
      >
        <p style={{ color: "#fff", fontFamily: "Poppins", fontSize: "14px" }}>
          For all other enqueries, Please fill this form and one of our
          representative will get touch with you.
        </p>

        <button
          style={{
            border: "none",
            color: "#155f82",
            width: "150px",
            height: "50px",
            borderRadius: "50px",
            padding: "13px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Get in Touch
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>

      <Footer />
    </div>
  );
};

const containerStyle = {
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
};

const headerStyle = {
  marginBottom: "20px",
  fontFamily: "Poppins",
};

const titleStyle = {
  fontSize: "2rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const imageStyle = {
  width: "100%", // Adjust the width as needed
  height: "400px",
  objectFit: "cover",
  marginTop: "77px",
};

const contentStyle = {
  fontSize: "1rem",
  fontFamily: "Poppins",
};

const sectionStyle = {
  marginBottom: "30px",
  fontFamily: "Poppins",
};

const sectionTitleStyle = {
  fontSize: "1.5rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const listStyle = {
  listStyleType: "disc",
  paddingLeft: "20px",
  fontFamily: "Poppins",
};

export default TechnoBI;

import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Headerpage from "../components/Header";
import Industrypage from "../components/Industry/Industrypage";
import ScrollToTopButton from "../components/Scrollbutton";

function Industry() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
     
          </div>
        </Fade>
      </div>
      <Industrypage />

      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

export default Industry;

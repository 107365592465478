import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { NavLink, useLocation, useParams } from "react-router-dom";
function Headerpage() {
  const [click, setClick] = React.useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showModal7, setShowModal7] = useState(false);
  const data = "1"; // Your state data here
  const data1 = "2";
  const data2 = "3";
  const data3 = "4";
  const data4 = "5";
  const data5 = "6";
  const data6 = "7";
  const handleClick = () => setClick(!click);
  const Close = () => {
    setClick(false);
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(false);
    setShowModal4(false);
    setShowModal5(false);
    setShowModal6(false);
    setShowModal7(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setClick(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleHover = (shouldShowModal) => {
    if (click) {
      return;
    }
    setShowModal(shouldShowModal);
  };
  const handleHover1 = (shouldShowModal) => {
    if (click) {
      return;
    }
    setShowModal1(shouldShowModal);
  };
  const handleHover2 = (shouldShowModal) => {
    if (click) {
      return;
    }
    setShowModal2(shouldShowModal);
  };
  const handleHover3 = (shouldShowModal) => {
    if (click) {
      return;
    }
    setShowModal3(shouldShowModal);
  };

  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav
        className="navbar"
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(false);
          setShowModal1(false);
          setShowModal2(false);
          setShowModal3(false);
          setShowModal4(false);
          setShowModal5(false);
          setShowModal6(false);
          setShowModal7(false);
        }}
      >
        <div className="nav-container">
          <div className="nav-container1">
            <NavLink to="/" className="">
              <img
                src="/img/logo.png"
                alt="Technowell_Logo"
                className="logoimage"
              />
            </NavLink>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li
                className="nav-item"
                // onMouseEnter={() => handleHover3(true)}
                // onMouseLeave={() => handleHover3(false)}
              >
                <NavLink
                  to="/research"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Research
                </NavLink>
              </li>

              <li
                className="nav-item"
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
              >
                <NavLink
                  to="/Industry"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Industry
                </NavLink>
                {showModal && (
                  <ul
                    style={{
                      position: "absolute",
                      listStyle: "none",
                      padding: "8px",
                      background: "white",
                      textAlign: "start",
                      boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                      top: "60px",
                      fontWeight: "600",
                      fontSize: "14px",
                      textAlign: "start",
                    }}
                  >
                    <li
                      onMouseEnter={() => setShowModal3(true)}
                      onMouseLeave={() => setShowModal3(false)}
                      style={{ width: "110%" }}
                    >
                      <NavLink
                        to={"/industry/educationaltechnologies"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Education Technology
                      </NavLink>
                    </li>
                    {showModal3 && (
                      <ul
                        style={{
                          position: "absolute",
                          left: "100%",
                          width: "100%",
                          listStyle: "none",
                          padding: "8px",
                          background: "white",
                          top: "0px",
                          boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "start",
                        }}
                      >
                        <li
                          onMouseEnter={() => setShowModal3(true)}
                          onMouseLeave={() => setShowModal3(false)}
                        >
                          <NavLink
                            to={"/products/elearningplatform"}
                            activeclassname="active"
                            className="nav-links"
                            onClick={click ? handleClick : null}
                          >
                            eLearning Platofrm
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    <li
                      onMouseEnter={() => {
                        setShowModal(true);
                        setShowModal4(true);
                        setShowModal1(false);
                      }}
                      onMouseLeave={() => {
                        setShowModal4(false);
                      }}
                      style={{ width: "110%" }}
                    >
                      <NavLink
                        to={"/industry/energyresourcesandutilities"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Energy Resources and Utilities
                      </NavLink>
                      {showModal4 && (
                        <ul
                          style={{
                            position: "absolute",
                            left: "100%",
                            width: "150%",
                            listStyle: "none",
                            padding: "8px",
                            background: "white",
                            top: "40px",
                            boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                            fontWeight: "600",
                            fontSize: "14px",
                            textAlign: "start",
                          }}
                        >
                          <li>
                            <NavLink
                              to={`/industry/energyresourcesandutilities?data=${data}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Agri Remote Sensing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`/industry/energyresourcesandutilities?data=${data1}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Forest Management System
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`/industry/energyresourcesandutilities?data=${data2}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Water & Environment Management System
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`/industry/energyresourcesandutilities?data=${data3}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Dam safety Management
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li
                      onMouseEnter={() => setShowModal5(true)}
                      onMouseLeave={() => setShowModal5(false)}
                      style={{ width: "105%" }}
                    >
                      <NavLink
                        to={"/industry/pharma?"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Pharma
                      </NavLink>
                      {showModal5 && (
                        <ul
                          style={{
                            position: "absolute",
                            left: "100%",
                            width: "150%",
                            listStyle: "none",
                            padding: "8px",
                            background: "white",
                            top: "85px",
                            boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                            fontWeight: "600",
                            fontSize: "14px",
                            textAlign: "start",
                          }}
                        >
                          <li
                            onMouseEnter={() => setShowModal5(true)}
                            onMouseLeave={() => setShowModal5(false)}
                          >
                            <NavLink
                              to={"/industry/pharma"}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Inventory and Logistics Management
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li
                      onMouseEnter={() => setShowModal6(true)}
                      onMouseLeave={() => setShowModal6(false)}
                      style={{ width: "105%" }}
                    >
                      <NavLink
                        to={"/industry/publicsector"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Public Sector
                      </NavLink>
                      {showModal6 && (
                        <ul
                          style={{
                            position: "absolute",
                            left: "100%",
                            width: "100%",
                            listStyle: "none",
                            padding: "8px",
                            background: "white",
                            top: "120px",
                            boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                            fontWeight: "600",
                            fontSize: "14px",
                            textAlign: "start",
                          }}
                        >
                          <li
                            onMouseEnter={() => setShowModal6(true)}
                            onMouseLeave={() => setShowModal6(false)}
                          >
                            <NavLink
                              to={"/industry/publicsector"}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              E Governance System
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                )}
              </li>

              <li
                className="nav-item"
                onMouseEnter={() => handleHover1(true)}
                onMouseLeave={() => handleHover1(false)}
              >
                <NavLink
                  to="/services"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Services
                </NavLink>
                {showModal1 && (
                  <ul
                    style={{
                      position: "absolute",
                      listStyle: "none",
                      padding: "8px",
                      background: "white",
                      textAlign: "start",
                      boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                      top: "60px",
                      fontWeight: "600",
                      fontSize: "14px",
                      textAlign: "start",
                    }}
                  >
                    <li>
                      <NavLink
                        to={"/services/consulting"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Consulting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/services/staffing"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/services/cad"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        CAD
                      </NavLink>
                    </li>
                    <li
                      onMouseEnter={() => setShowModal7(true)}
                      onMouseLeave={() => setShowModal7(false)}
                      style={{ width: "105%" }}
                    >
                      <NavLink
                        to={"/services/geosptialservices"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Geo Spatial Services
                      </NavLink>
                      {showModal7 && (
                        <ul
                          style={{
                            position: "absolute",
                            left: "100%",
                            width: "100%",
                            listStyle: "none",
                            padding: "8px",
                            background: "white",
                            top: "120px",
                            boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                            fontWeight: "600",
                            fontSize: "14px",
                            textAlign: "start",
                          }}
                          onMouseEnter={() => setShowModal7(true)}
                          onMouseLeave={() => setShowModal7(false)}
                        >
                          <li>
                            <NavLink
                              to={`/services/geosptialservices?data=${data4}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              Land Records
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`/services/geosptialservices?data=${data5}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              GIS Based Web Application
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`/services/geosptialservices?data=${data6}`}
                              activeclassname="active"
                              className="nav-links"
                              onClick={click ? handleClick : null}
                            >
                              GIS Based Mobile Application
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <NavLink
                        to={"/services/marketresearch"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Market Research
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/services/enterpriseresourceapplication"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Enterprise Resource Application
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className="nav-item"
                onMouseEnter={() => handleHover2(true)}
                onMouseLeave={() => handleHover2(false)}
              >
                <NavLink
                  to="/products"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Products
                </NavLink>
                {showModal2 && (
                  <ul
                    style={{
                      position: "absolute",
                      listStyle: "none",
                      padding: "8px",
                      background: "white",
                      textAlign: "start",
                      boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.4)",
                      top: "60px",
                      fontWeight: "600",
                      fontSize: "14px",
                      textAlign: "start",
                    }}
                  >
                    <li>
                      <NavLink
                        to={"/products/elearningplatform"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        E learning Platform
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/products/dms"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Document Management System
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/products/ams"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Asset Management System
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/products/technobi"}
                        activeclassname="active"
                        className="nav-links"
                        onClick={click ? handleClick : null}
                      >
                        Techno BI
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <NavLink
                  to="/aboutus"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contactus"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="nav-icon" onClick={handleClick}>
            {click ? (
              <span className="icon">
                <img src="/img/cross.svg" alt="menu" width={18} />
              </span>
            ) : (
              <span className="icon">
                <img src="/img/menu.svg" alt="menu" width={30} />{" "}
              </span>
            )}{" "}
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Headerpage;

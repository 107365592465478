import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../Header";
import { Fade } from "react-reveal";

export default function Servicespage1() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  const IndustryCard = React.memo(({ imageSrc, title, to }) => {
    return (
      <div className="induistryminicard">
        <NavLink to={to} className="nav-links">
          <img
            src={imageSrc}
            alt={title}
            className="industry-image"
            loading="lazy"
          />
          <p className="industry-title">{title}</p>
        </NavLink>
      </div>
    );
  });

  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>
      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Services
          </p>
        </div>
      </div>
      <Fade bottom>
        <div className="industrycards">
          <IndustryCard
            imageSrc="/img/imagesrc/Consulting.jpeg"
            title="Consulting"
            to={"/services/consulting"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/staffing.jpeg"
            title="Staffing"
            to={"/services/staffing"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/CAD.jpeg"
            title="Cad"
            to={"/services/cad"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Geo Spatial Services.jpg"
            title="Geo Spatial Services"
            to={"/industry/geosptialservices"}
          />
        </div>
      </Fade>
      <Fade bottom>
        <div className="industrycards">
          <IndustryCard
            imageSrc="/img/imagesrc/market research.jpg"
            title="Market Research"
            to={"/services/marketresearch"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Enterprise Resource Mgmt.jpeg"
            title="Enterprise Resource Application"
            to={"/services/enterpriseresourceapplication"}
          />

          <div style={{ width: "20%" }}></div>
          <div style={{ width: "20%" }}></div>
        </div>
      </Fade>

      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
}

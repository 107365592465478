import React from "react";
import { Fade } from "react-reveal";
import { NavLink, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Industrypage() {
  const location = useLocation();

  const IndustryCard = React.memo(({ imageSrc, title, to }) => {
    return (
      <div className="induistryminicard">
        <NavLink to={to} className="nav-links">
          <img
            src={imageSrc}
            alt={title}
            className="industry-image"
            loading="lazy"
          />
          <p className="industry-title">{title}</p>
        </NavLink>
      </div>
    );
  });

  return (
    <div>
      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Industry
          </p>
        </div>
      </div>

      <Fade bottom>
        <div className="industrycards">
          <IndustryCard
            imageSrc="/img/imagesrc/elearning.jpg"
            title="Education Technology"
            to={"/industry/educationaltechnologies"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Energy Resource and Utilities.jpeg"
            title="Energy Resources and Utilities"
            to={"/industry/energyresourcesandutilities"}
          />

          <IndustryCard
            imageSrc="/img/img/Pharma.jpeg"
            title="Pharma"
            to={"/industry/pharma"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Public Sector.jpeg"
            title="Public Sector"
            to={"/industry/publicsector"}
          />
        </div>
      </Fade>

      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ScrollToTopButton from "../Scrollbutton";
import Footer from "../Footer";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
export default function AssetManagement() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
          <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>
  
       <div
        style={{ display: "flex", width: "100%", height: "400px",marginTop:"77px" }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Asset Management.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3
          style={{ fontFamily: "Poppins", fontWeight: 700, color: "#155f82" }}
        >
          Asset Management System (AMS)
        </h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Asset Management System for water assets is a comprehensive solution
          designed to efficiently track, manage, and optimize water
          infrastructure. It integrates metadata, enabling detailed asset
          information and facilitating informed decision-making. The system
          includes features such as condition monitoring, GIS integration,
          predictive maintenance, and regulatory compliance to ensure the
          reliability, sustainability, and compliance of water assets. With
          mobile accessibility, robust security, and scalability, it provides a
          holistic solution for water resource management, enhancing operational
          efficiency and promoting data-driven insights.
        </p>
      </div>
      {/* <div
        style={{ display: "flex", width: "100%", height: "400px" }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Public Sector.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div> */}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Key Features
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>
            • Integration of comprehensive metadata for detailed asset
            information.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Condition monitoring through sensors and loT devices.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • GIS integration for spatial analysis and planning.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Mobile accessibility for on-site updates and efficiency.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Robust security measures to protect sensitive data.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Detailed audit trails for transparency and accountability.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Seamless integration with enterprise systems.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Detailed audit trails for transparency and accountability.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Reporting and analytics for informed decision-making.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Comprehensive user training for effective utilization.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Customization to meet specific water asset requirements.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginLeft: "-10%",
          marginBottom: "50px",
        }}
      >
        {/* <p style={{ fontFamily: "Poppins" }}>Want to know more?</p> */}
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "180px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px black",
            height: "40px",
            marginLeft: "4%",
            cursor:"pointer"
          }}
          onClick={()=>navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />

<Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./Educationaltech.css";
import Headerpage from "../../Header";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function EducationalTech() {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <Fade top>
        <div style={navbarStyle}>
          <Headerpage />
        </div>
      </Fade>

      <div style={{ marginTop: "70px" }}>
        <div
          style={{ display: "flex", width: "100%", height: "400px" }}
          className="elarnimgcard"
        >
          <img
            src="/img/imagesrc/elearning.jpg"
            style={{
              display: "flex",
              width: "100%",
              display: "block",
              objectFit: "cover",
            }}
            className="elaernlogo"
          />
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>
            Education Technology
          </h3>
        </div>
        <div
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: 400,
              width: "60%",
              textAlign: "center",
              fontFamily: "Poppins",
            }}
            className="educationdesc"
          >
            In the spirit of collaboration between industry and academia,
            Technowell stands at the forefront of reshaping the broader
            landscape of education. By embracing forward-thinking initiatives,
            we contribute to cultivating a workforce that is not just prepared
            for the future but actively shaping it.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            marginTop: "20px",
            marginBottom: "50px",
          }}
          className="elearncard"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "#155f82",
              fontWeight: 600,
            }}
          >
            eLearning Platform
          </p>
          <div
            style={{
              fontFamily: "Poppins",
              width: "50%",
              marginLeft: "2%",
              flexDirection: "column",
            }}
            className="elearndesc"
          >
            <p style={{ fontFamily: "Poppins" }}>
              A enterprise custom digital system for comprehensive online
              education delivery from start to finish honed based on user
              requirements.
            </p>
            <button
              style={{
                border: "2px solid #155f82",
                color: "#155f82",
                width: "150px",
                borderRadius: "50px",
                padding: "10px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "center",
                boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
                cursor: "pointer",
              }}
              onClick={() => navigate("/products/elearningplatform")}
            >
              Learn more
              <img
                src="/img/right-arrow copy.svg"
                style={{ width: 20, marginLeft: "10px" }}
              />
            </button>
          </div>
        </div>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

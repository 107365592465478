import React, { useEffect, useState } from "react";
import "./Energyresources.css";
import {
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Fade } from "react-reveal";
import Headerpage from "../../Header";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function EnergyResources(props) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const data = params.get("data");

  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  const [selectedButton, setSelectedButton] = useState("Agri Remote Sensing");
  useEffect(() => {
    setSelectedButton(
      data === "1"
        ? "Agri Remote Sensing"
        : data === "2"
        ? "Forest Management System"
        : data === "3"
        ? "Water & Environment Management System"
        : data === "4"
        ? "Dam safety Management"
        : "Agri Remote Sensing"
    );
  }, [data]);
  const buttonData = {
    "Agri Remote Sensing": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          Technowell has established a significant presence in the realm of
          agricultural remote sensing, bringing cutting-edge technology to
          revolutionize the way we perceive and manage agriculture.
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          Our advanced agri-tech solutions harness the power of satellite
          imagery, drones, and other remote sensing technologies to provide
          farmers with invaluable insights into crop health, soil conditions,
          and overall field management. By integrating data analytics and
          artificial intelligence, Technowell ensures that farmers receive
          real-time, actionable information, enabling them to make informed
          decisions for optimizing yield and resource utilization.
        </p>
      </div>
    ),
    "Forest Management System": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          Technowell, in collaboration with MFI Document Solutions, leads the
          way in advancing forest management systems across the African region.
          Our cutting-edge capabilities, powered by state-of-the-art Geographic
          Information System (GIS) solutions, play a pivotal role in promoting
          environmental sustainability. The developed portal and database not
          only facilitate efficient forest management but also archive critical
          information, including nursery audits, seedling distribution, seedling
          monitoring, and tracking forest encroachments.
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          We are partners with Bhupraman to deliver intelligent forest
          technology interventions. This collaboration signifies our commitment
          to providing Indian clients with innovative solutions that enhance
          forest monitoring, conservation, and sustainable resource management.
          Our presence in both regions underscores our dedication to leveraging
          technology for the betterment of global forest ecosystems.
        </p>
      </div>
    ),
    "Water & Environment Management System": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          Technowell, in collaboration with MFI Document Solutions, is a
          prominent player in water management, demonstrating expertise and a
          robust presence in this vital sector. At the forefront of our
          contributions is the Water and Environment Information System (WEIS),
          a sophisticated GIS-based application enhancing water resource
          understanding and management, particularly in regions like Uganda.
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          Beyond this, our solutions extend to borehole management, document
          streamlining through the Document Management Portal, and Hydrological
          Data Aggregation, fostering collaboration in water-related datasets.
          Technowell's commitment to advancing water management reflects in our
          innovative solutions that address the complex challenges of water
          resource sustainability.
        </p>
      </div>
    ),
    "Dam safety Management": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          The Dam Safety Portal developed by Technowell stands as a
          state-of-the-art system designed for real-time monitoring of critical
          water assets, particularly dams. This portal employs advanced
          technologies to continuously assess the structural integrity,
          performance, and safety of dams, ensuring timely detection of any
          anomalies or potential risks. Through real-time data visualization and
          analytics, stakeholders can proactively manage and mitigate potential
          dam safety issues.
        </p>
      </div>
    ),
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Energy Resource and Utilities.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>
          Energy Resources and Utilities
        </h3>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginBottom: "50px",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          The collaboration between industry and technology envisions a future
          where energy resources are optimized, utilities are smarter, and the
          overall energy ecosystem is more responsive to the needs of a rapidly
          evolving world. The utilization of emerging technologies, coupled with
          a focus on sustainability, ensures a dynamic and resilient energy
          sector. As we navigate the transition towards a cleaner and more
          efficient energy future, Technowell stands as a trailblazer, offering
          innovative solutions that transcend the conventional paradigms of
          energy production and consumption.
        </p>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
          flexDirection: "row",
          justifyContent: "center",
        }}
        className="elearncard"
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "20%" }}
          className="energyselection"
        >
          {Object.keys(buttonData).map((buttonName) => (
            <div
              key={buttonName}
              onClick={() => handleButtonClick(buttonName)}
              style={{
                fontFamily: "Poppins",
                width: "100%",
                fontWeight: 700,
                color: selectedButton === buttonName ? "#155f82" : "grey",
                padding: "10px",
                marginBottom: "5px",
                cursor: "pointer",
              }}
            >
              {buttonName}
            </div>
          ))}
        </div>

        {selectedButton && buttonData[selectedButton]}
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../../Header";
import { Fade } from "react-reveal";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function Consulting() {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div style={{ marginTop: "77px" }}>
      <Fade top>
        <div style={navbarStyle}>
          <Headerpage />

        </div>
      </Fade>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Consulting.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>Consulting</h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Technowell, specializes in delivering innovative solutions tailored to
          diverse industries. Our expertise spans technology, strategy, and
          implementation, providing clients with strategic insights and
          operational excellence. As consultants, we navigate challenges, drive
          efficiency, and empower organizations to thrive in an ever-evolving
          business landscape.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Key functionalities developed so far
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>• MIS and GIS integration</p>
          <p style={{ fontFamily: "Poppins" }}>
            • Data analysis with thematic maps and required charts
          </p>
          <p style={{ fontFamily: "Poppins" }}>• ULB's work flow atomization</p>
          <p style={{ fontFamily: "Poppins" }}>
            • Smart cities concepts atomization
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • House hold based GIS data analysis
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Online Building/Household/ Member creation
          </p>
        </div>
      </div>
      <div
        style={{
          marginBottom: "100px",
          justifyContent: "end",
          width: "100%",
          display: "flex",
        }}
      >
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "150px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

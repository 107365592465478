import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to check if the user has scrolled beyond a certain height.
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component is unmounted.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' for an instant scroll
    });
  };

  return (
     <div> 
     <img src='/img/up-arrow.svg' onClick={scrollToTop}  className='buttonscrolling'
     style={{display: isVisible ? 'inline' : 'none'}} /> 
    </div> 
  );
}

export default ScrollToTopButton;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../../Header";
import { Fade } from "react-reveal";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function MobileResearch() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/market research.jpg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>
          Market Research 
        </h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Technowell offers market research , leveraging innovative
          solutions for data-driven insights. With a notable project
          collaboration with HCL Foundation, our services extend to impactful
          market research strategies, ensuring organizations make informed
          decisions based on comprehensive data analysis and industry trends.
        </p>
      </div>
      <div
        style={{
          marginBottom: "100px",
          justifyContent: "end",
          width: "100%",
          display: "flex",
        }}
      >
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "150px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor:"pointer"
          }}
          onClick={()=>navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />

<Footer />
    </div>
  );
}

import React, { useState } from "react";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const AgricultureAiMl = () => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Transforming Agriculture with AI and Machine
            Learning
          </p>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={headerStyle}>
          <h1 style={titleStyle}>
            Technowell’s Cutting-Edge Research: Transforming Agriculture with AI
            and Machine Learning
          </h1>
          <img
            src="/img/imagesrc/agriculture.jpeg"
            alt="AR and VR"
            style={imageStyle}
          />
          <p style={contentStyle}>
            In the dynamic landscape of agriculture, Technowell emerges as a
            beacon of innovation, leading the charge in harnessing the power of
            Artificial Intelligence (AI) and Machine Learning (ML). Their
            groundbreaking research is reshaping how farmers cultivate crops,
            monitor fields, and predict yields, bringing unprecedented precision
            to every aspect of agriculture.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Precision Farming: Cultivating Efficiency with AI
          </h2>
          <p style={contentStyle}>
            Technowell’s foray into Precision Farming is revolutionizing
            traditional agricultural practices. By integrating AI algorithms,
            farmers gain a level of precision previously unimaginable.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Achievements in Precision Farming:
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Automated Resource Allocation :</b> Technowell’s AI solutions
              optimize the use of resources such as water, fertilizers, and
              pesticides, ensuring they are applied precisely where and when
              needed.
            </li>
            <li>
              <b>Sensor Integration :</b> Advanced sensors, coupled with AI,
              provide real-time data on soil health, enabling farmers to make
              data-driven decisions for optimal crop growth.
            </li>
            <li>
              <b> Predictive Crop Health :</b> Machine Learning models predict
              potential diseases and pest infestations, allowing farmers to take
              proactive measures, thereby enhancing overall crop health.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Crop Monitoring and Management: A New Dawn for Agriculture
          </h2>
          <p style={contentStyle}>
            Technowell’s commitment to Crop Monitoring and Management is evident
            in their AI-driven systems that provide farmers with a comprehensive
            view of their fields.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Features of Crop Monitoring and Management:
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Real-Time Field Imaging :</b> Drones equipped with AI analyze
              field images, offering insights into crop health, growth patterns,
              and potential issues
            </li>
            <li>
              <b> Disease Detection Algorithms :</b> ML algorithms detect early
              signs of diseases, helping farmers address problems before they
              escalate.
            </li>
            <li>
              <b> Automated Farm Machinery :</b> Integration of AI in farm
              machinery ensures precise and efficient operations, from seeding
              to harvesting.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Predictive Analytics for Weather and Climate: Navigating the
            Elements{" "}
          </h2>
          <p style={contentStyle}>
            Technowell’s research extends into predicting weather patterns and
            climate impacts, providing farmers with crucial insights to adapt
            their strategies accordingly.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Highlights of Predictive Analytics for Weather and Climate:
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Historical Data Analysis :</b> AI analyzes historical weather
              data to make accurate predictions, helping farmers anticipate and
              prepare for changing climate conditions.
            </li>
            <li>
              <b> Climate-Responsive Farming :</b> Insights from predictive
              analytics enable farmers to adopt climate-responsive farming
              practices, ensuring resilience in the face of climate change.
            </li>
          </ul>
        </div>
        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Crop Yield Prediction: Ensuring Food Security
          </h2>
          <p style={contentStyle}>
            Technowell’s AI-driven Crop Yield Prediction models are pivotal in
            ensuring global food security by providing accurate forecasts.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Components of Crop Yield Prediction:
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Multi-Factor Analysis :</b> ML models consider various factors,
              including weather, soil conditions, and historical data, to
              predict crop yields with precision.s.
            </li>
            <li>
              <b> Supply Chain Optimization :</b> Accurate predictions aid in
              optimizing the agricultural supply chain, reducing waste, and
              ensuring a stable food supply.
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "#1f5d7d",
          marginRight: "auto",
          marginLeft: "auto",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "5px",
        }}
      >
        <p style={{ color: "#fff", fontFamily: "Poppins", fontSize: "14px" }}>
          For all other enqueries, Please fill this form and one of our
          representative will get touch with you.
        </p>

        <button
          style={{
            border: "none",
            color: "#155f82",
            width: "150px",
            height: "50px",
            borderRadius: "50px",
            padding: "13px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Get in Touch
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>

      <Footer />
    </div>
  );
};

const containerStyle = {
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
};

const headerStyle = {
  marginBottom: "20px",
  fontFamily: "Poppins",
};

const titleStyle = {
  fontSize: "2rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const imageStyle = {
  width: "100%", // Adjust the width as needed
  height: "auto",
  borderRadius: "10px", // Add border-radius for rounded corners
};

const contentStyle = {
  fontSize: "1rem",
  fontFamily: "Poppins",
};

const sectionStyle = {
  marginBottom: "30px",
  fontFamily: "Poppins",
};

const sectionTitleStyle = {
  fontSize: "1.5rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const listStyle = {
  listStyleType: "disc",
  paddingLeft: "20px",
  fontFamily: "Poppins",
};

export default AgricultureAiMl;

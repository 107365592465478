import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";

function Homepage2() {

  return (
 <div style={{marginBottom:"-10px"}}>
  <img src="/img/imagesrc/01.png" style={{width:"100%"}}/>
    </div>
  );
}

export default Homepage2;

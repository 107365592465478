import React, { useState } from "react";
import { Fade } from "react-reveal";
import Headerpage from "../Header";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const WaterQualityAiMl = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Water Quality Analysis with AI/ML
          </p>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={headerStyle}>
          <h1 style={titleStyle}>
            Technowell's Breakthrough: Elevating Water Quality Analysis with
            AI/ML
          </h1>
          <img
            src="/img/imagesrc/WQ Home Page.jpeg"
            alt="AR and VR"
            style={imageStyle}
          />
          <p style={contentStyle}>
            In the pursuit of a sustainable and healthy environment, Technowell
            is at the forefront of innovation, leveraging Artificial
            Intelligence (AI) and Machine Learning (ML) to redefine water
            quality analysis. Through extensive research, Technowell has
            developed cutting-edge solutions that analyze various parameters
            captured during lab testing, revolutionizing how we monitor and
            maintain water quality.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            AI/ML for Comprehensive Water Quality Assessment{" "}
          </h2>
          <p style={contentStyle}>
            Technowell's groundbreaking approach to water quality analysis
            involves the integration of AI and ML algorithms, transforming
            traditional lab testing methods.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Features of Technowell's Water Quality Analysis:
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Parameter Diversity :</b> Technowell's AI models consider a
              diverse set of parameters obtained through lab testing, including
              chemical composition, microbial content, and physical
              characteristics of water.
            </li>
            <li>
              <b>Real-Time Analysis :</b> Through the power of ML, Technowell
              enables real-time analysis of water quality data, providing
              immediate insights into the health of water sources.
            </li>
            <li>
              <b>Predictive Modeling :</b> ML algorithms predict potential
              variations in water quality based on historical data, aiding in
              proactive decision-making for water management.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Lab Testing Reinvented</h2>
          <p style={contentStyle}>
            Technowell's research has redefined the very essence of lab testing
            for water quality. The traditional approach is enhanced by AI,
            bringing unparalleled accuracy and efficiency to the process.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Transformative Aspects of Technowell's Lab Testing:
          </h2>
          <ul style={listStyle}>
            <li>
              <b> Automated Data Processing :</b> AI algorithms automate the
              processing of lab results, reducing human error and significantly
              expediting the analysis process.
            </li>
            <li>
              <b>Pattern Recognition :</b> ML models identify subtle patterns in
              water quality data that might be challenging for manual analysis,
              ensuring a more comprehensive and precise assessment.
            </li>
            <li>
              <b> Dynamic Calibration :</b> Technowell's systems dynamically
              calibrate based on evolving data, ensuring that the analysis
              remains accurate even as environmental conditions change.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Environmental Impact and Sustainability
          </h2>
          <p style={contentStyle}>
            Technowell's commitment extends beyond technological advancements;
            it's deeply rooted in environmental stewardship and sustainability.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Positive Impacts on the Environment:
          </h2>
          <ul style={listStyle}>
            <li>
              <b> Early Contamination Detection :</b> Swift identification of
              contaminants through AI/ML contributes to early intervention,
              minimizing the environmental impact of pollution.
            </li>
            <li>
              <b> Resource Optimization :</b> By optimizing water treatment
              processes based on AI recommendations, Technowell aids in the
              efficient use of resources, contributing to sustainability goals.
            </li>
            <li>
              <b> Data-Driven Conservation :</b> Technowell's solutions empower
              communities and authorities with data-driven insights, fostering
              responsible water management and conservation efforts.
            </li>
          </ul>
        </div>
        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Future Outlook: Redefining Water Quality Standards
          </h2>
          <p style={contentStyle}>
            As Technowell continues to push the boundaries of innovation in
            AI/ML for water quality analysis, the future holds exciting
            possibilities.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>Anticipated Developments:</h2>
          <ul style={listStyle}>
            <li>
              <b> IoT Integration :</b> Exploring integration with Internet of
              Things (IoT) devices to enable real-time data collection from
              various water sources.
            </li>
            <li>
              <b>Expanded Parameter Analysis :</b> Continuously expanding the
              parameters considered for analysis to provide an even more
              comprehensive view of water quality.
            </li>
            <li>
              <b> Global Collaboration :</b> Collaborating with environmental
              agencies and organizations to implement Technowell's solutions on
              a global scale, contributing to universal water quality standards.
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "#1f5d7d",
          marginRight: "auto",
          marginLeft: "auto",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "5px",
        }}
      >
        <p style={{ color: "#fff", fontFamily: "Poppins", fontSize: "14px" }}>
          For all other enqueries, Please fill this form and one of our
          representative will get touch with you.
        </p>

        <button
          style={{
            border: "none",
            color: "#155f82",
            width: "150px",
            height: "50px",
            borderRadius: "50px",
            padding: "13px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Get in Touch
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>

      <Footer />
    </div>
  );
};

const containerStyle = {
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
};

const headerStyle = {
  marginBottom: "20px",
  fontFamily: "Poppins",
};

const titleStyle = {
  fontSize: "2rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const imageStyle = {
  width: "100%", // Adjust the width as needed
  height: "auto",
  borderRadius: "10px", // Add border-radius for rounded corners
};

const contentStyle = {
  fontSize: "1rem",
  fontFamily: "Poppins",
};

const sectionStyle = {
  marginBottom: "30px",
  fontFamily: "Poppins",
};

const sectionTitleStyle = {
  fontSize: "1.5rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const listStyle = {
  listStyleType: "disc",
  paddingLeft: "20px",
  fontFamily: "Poppins",
};

export default WaterQualityAiMl;

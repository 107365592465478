import React, { useEffect, useState } from "react";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
import { Fade } from "react-reveal";
import Headerpage from "../../Header";
import { useLocation, useNavigate } from "react-router-dom";
export default function Geopsatialservices() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const data = params.get("data");
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  const [selectedButton, setSelectedButton] = useState("Land Records");
  useEffect(() => {
    setSelectedButton(
      data === "5"
        ? "Land Records"
        : data === "6"
        ? "GIS Based Web Application"
        : data === "7"
        ? "GIS Based Mobile Application"
        : "Land Records"
    );
  }, [data]);
  const buttonData = {
    "Land Records": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          Technowell specializes in creating Geographic Information System (GIS)
          solutions tailored for efficient property tax collection. These
          software applications leverage spatial data to enhance property
          assessment, streamline tax collection processes, and provide
          municipalities with comprehensive tools for effective revenue
          management.
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          Technowell's expertise extends to the development of enterprise-level
          applications for water resource management. Leveraging GIS technology,
          these applications provide comprehensive insights into water
          distribution, usage, and conservation. The goal is to empower
          organizations to make informed decisions regarding water resource
          planning and sustainability.
        </p>
      </div>
    ),
    "GIS Based Web Application": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          • Various layers management as per client requirement
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          • Inbuilt query builder to extract data in terms of maps and tables
        </p>{" "}
        <p style={{ fontFamily: "Poppins" }}>
          • GIS and Geo fencing based employee attendance and movement tracking
        </p>
        <p style={{ fontFamily: "Poppins" }}>• Navigation tools</p>
        <p style={{ fontFamily: "Poppins" }}>
          • Query builder for GIS and MIS data
        </p>
        <p style={{ fontFamily: "Poppins" }}>• Thematic maps</p>
        <p style={{ fontFamily: "Poppins" }}>
          • Distance and area calculations
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
          className="webmapimages"
        >
          <img src="/img/imagesrc/web1.jpeg" width={200} />
          <img src="/img/imagesrc/web2.jpeg" width={200} />
          <img src="/img/imagesrc/web3.jpeg" width={200} />
        </div>
      </div>
    ),
    "GIS Based Mobile Application": (
      <div
        style={{
          fontFamily: "Poppins",
          width: "50%",
          marginLeft: "2%",
          flexDirection: "column",
        }}
        className="elearndesc"
      >
        <p style={{ fontFamily: "Poppins" }}>
          • Creation of various data points as polygons or lines with accurate
          geo locations
        </p>
        <p style={{ fontFamily: "Poppins" }}>
          • User can work online and offline working conditions
        </p>
        <p style={{ fontFamily: "Poppins" }}>• Layer management</p>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
          className="mobimages"
        >
          <img
            src="/img/imagesrc/mob1.jpeg"
            height={200}
            width={100}
            style={{ marginLeft: "10px", marginTop: "10px" }}
          />
          <img
            src="/img/imagesrc/mob2.jpeg"
            height={200}
            width={100}
            style={{ marginLeft: "10px", marginTop: "10px" }}
          />
          <img
            src="/img/imagesrc/mob3.jpeg"
            height={200}
            width={100}
            style={{ marginLeft: "10px", marginTop: "10px" }}
          />
          <img
            src="/img/imagesrc/mob4.jpeg"
            height={200}
            width={100}
            style={{ marginLeft: "10px", marginTop: "10px" }}
          />
          <img
            src="/img/imagesrc/mob5.jpeg"
            height={200}
            width={100}
            style={{ marginLeft: "10px", marginTop: "10px" }}
          />
        </div>
      </div>
    ),
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Geo Spatial Services.jpg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>
          Geo Spatial Services
        </h3>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginBottom: "20px",
        }}
      >
        <p
          style={{
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          We are specialized in GIS technology, since Technowell inception we
          are continuously working on GIS projects which are related to ULBS,
          NGOs and CSR projects. The same are helping respective clients to
          monitor information geographically and decision making.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginBottom: "50px",
        }}
      >
        <p
          style={{
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Technowell developing a GIS based products on Web application and
          Mobile application platforms too. Generally, we develop our mobile
          applications for data collection process and web application for
          presentation purpose with thematic maps and required analytic tools.
          We use vector and raster data structures for better productivity.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
          flexDirection: "row",
          justifyContent: "center",
        }}
        className="elearncard"
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "20%" }}
          className="energyselection"
        >
          {Object.keys(buttonData).map((buttonName) => (
            <div
              key={buttonName}
              onClick={() => handleButtonClick(buttonName)}
              style={{
                fontFamily: "Poppins",
                width: "100%",
                fontWeight: 700,
                color: selectedButton === buttonName ? "#155f82" : "grey",
                padding: "10px",
                marginBottom: "5px",
                cursor: "pointer",
              }}
            >
              {buttonName}
            </div>
          ))}
        </div>

        {selectedButton && buttonData[selectedButton]}
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

import React from "react";
import Servicespage1 from "../components/services/Servicespage1";
import ScrollToTopButton from "../components/Scrollbutton";
import Footer from "../components/Footer";


function Services() {

  return (
    <div>
      <Servicespage1 />
      <ScrollToTopButton/>
      <Footer />
    </div>
  );
}
export default Services;

import React, { useState } from "react";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
const ARandVR = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Research in AR and VR for Utilities and Fault
            Detection
          </p>
        </div>
      </div>

      <div style={containerStyle}>
        <div style={headerStyle}>
          <h1 style={titleStyle}>
            Research in AR and VR for Utilities and Fault Detection
          </h1>
          <img src="/img/imagesrc/AR.jpeg" alt="AR and VR" style={imageStyle} />
          <p style={contentStyle}>
            In the ever-evolving landscape of technology, Technowell stands at
            the forefront of innovation, particularly in the realm of Augmented
            Reality (AR) and Virtual Reality (VR). Their groundbreaking research
            is reshaping how utilities are managed and faults are detected,
            ushering in a new era of efficiency and precision.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Unleashing the Power of Augmented Reality
          </h2>
          <p style={contentStyle}>
            Technowell’s research in AR has yielded transformative applications
            for utility management. By overlaying digital information onto the
            physical world, AR enhances the capabilities of utility workers in
            the field. Imagine technicians equipped with AR glasses that provide
            real-time data, schematics, and diagnostics as they navigate through
            complex utility infrastructures.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Advancements in AR for Utilities
          </h2>
          <ul style={listStyle}>
            <li>
              <b> Remote Assistance :</b> Technowell’s AR solutions enable
              experts to provide remote assistance to on-site technicians. This
              not only expedites problem-solving but also reduces the need for
              travel, resulting in cost savings and quicker issue resolution.
            </li>
            <li>
              <b>Interactive Training Modules :</b> The integration of AR into
              training modules allows utility personnel to engage in immersive
              and interactive learning experiences. This ensures that teams are
              well-prepared to handle diverse scenarios in the field.
            </li>
            <li>
              <b> Asset Visualization :</b> AR technology allows for the
              visualization of underground utilities or hidden components,
              empowering workers to make informed decisions and avoid potential
              hazards.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Virtual Reality Revolutionizing Fault Detection
          </h2>
          <p style={contentStyle}>
            Technowell’s research extends into the realm of Virtual Reality,
            where simulations and immersive environments play a pivotal role in
            fault detection and resolution.
          </p>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Key Features of VR for Fault Detection
          </h2>
          <ul style={listStyle}>
            <li>
              <b>Simulated Fault Scenarios :</b> Virtual Reality simulations
              recreate various fault scenarios, allowing technicians to practice
              and refine their response strategies in a risk-free environment.
            </li>
            <li>
              <b>Data Analysis in VR :</b> Technowell’s VR solutions enable the
              visualization and analysis of complex data sets related to utility
              performance and potential faults. This facilitates proactive
              maintenance and minimizes downtime.
            </li>
            <li>
              <b>Collaborative Problem-Solving :</b> VR environments facilitate
              collaborative problem-solving sessions, bringing together experts
              from different locations to collectively address complex utility
              issues.
            </li>
          </ul>
        </div>

        <div style={sectionStyle}>
          <h2 style={sectionTitleStyle}>
            Commitment to Innovation and Sustainability
          </h2>
          <p style={contentStyle}>
            Technowell’s dedication to innovation goes hand-in-hand with a
            commitment to sustainability. By harnessing the capabilities of AR
            and VR, they contribute to the optimization of utility operations,
            reducing environmental impact and enhancing the resilience of
            critical infrastructure.
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          background: "#1f5d7d",
          marginRight: "auto",
          marginLeft: "auto",
          alignItems: "center",
          justifyContent: "space-around",
          padding: "5px",
        }}
      >
        <p style={{ color: "#fff", fontFamily: "Poppins", fontSize: "14px" }}>
          For all other enqueries, Please fill this form and one of our
          representative will get touch with you.
        </p>

        <button
          style={{
            border: "none",
            color: "#155f82",
            width: "150px",
            height: "50px",
            borderRadius: "50px",
            padding: "13px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Get in Touch
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>

      <Footer />
    </div>
  );
};

const containerStyle = {
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
};

const headerStyle = {
  marginBottom: "20px",
  fontFamily: "Poppins",
};

const titleStyle = {
  fontSize: "2rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const imageStyle = {
  width: "100%", // Adjust the width as needed
  height: "auto",
  borderRadius: "10px", // Add border-radius for rounded corners
};

const contentStyle = {
  fontSize: "1rem",
  fontFamily: "Poppins",
};

const sectionStyle = {
  marginBottom: "30px",
  fontFamily: "Poppins",
};

const sectionTitleStyle = {
  fontSize: "1.5rem",
  marginBottom: "10px",
  fontFamily: "Poppins",
};

const listStyle = {
  listStyleType: "disc",
  paddingLeft: "20px",
  fontFamily: "Poppins",
};

export default ARandVR;

import React, { useEffect, useState } from "react";
import ScrollToTopButton from "../Scrollbutton";
import Footer from "../Footer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import Headerpage from "../Header";
export default function Elearningplatform() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
          justifyContent:"center"

        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/elearning 1.png"
          style={{
            display: "flex",
            width: "50%",
            display: "block",
            objectFit: "contain",
          }}
          className="elaernlogo"
        />
        <img
          src="/img/imagesrc/elearning 2.png "
          style={{
            display: "flex",
            width: "50%",
            display: "block",
            objectFit: "contain",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3
          style={{ fontFamily: "Poppins", fontWeight: 700, color: "#155f82" }}
        >
          eLearning Platform
        </h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          A enterprise custom digital system for comprehensive online education
          delivery from start to finish honed based on user requirements.
        </p>
      </div>

      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Key Features
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>
            • Create unlimited number of courses.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Create custom pricing for each course.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Customize timelines for course purchases.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Unlimited number of user enrollment.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Supports wide range of file types such as documents, videos and
            audio files.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Manage the registered users.
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Track your revenue generated along with individual course revenue
            breakdown.
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p style={{ fontFamily: "Poppins" }}>Want to know more?</p>
            <button
              style={{
                border: "2px solid #155f82",
                color: "#155f82",
                width: "180px",
                borderRadius: "50px",
                padding: "10px",
                fontFamily: "Poppins",
                display: "flex",
                justifyContent: "center",
                boxShadow: "5px 5px 10px black",
                height: "40px",
                marginLeft: "4%",
                cursor: "pointer",
              }}
              onClick={() => navigate("/contactus")}
            >
              Contact us
              <img
                src="/img/right-arrow copy.svg"
                style={{ width: 20, marginLeft: "10px" }}
              />
            </button>
          </div>
        </div>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

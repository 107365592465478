import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Headerpage from "../components/Header";

const researchData = [
  {
    imageSrc: "/img/imagesrc/WQ Home Page.jpeg",
    title:
      "Technowell's Breakthrough: Elevating Water Quality Analysis with AI/ML",
    content:
      "In the pursuit of a sustainable and healthy environment, Technowell is at the forefront of innovation, leveraging Artificial Intelligence (AI) and Machine Learning (ML) to redefine water quality analysis. Through extensive research, Technowell has developed cutting-edge solutions that analyze various parameters captured during lab testing, revolutionizing how we monitor and maintain water quality.",
    to: "/research/waterqualitywithaiandml",
  },
  {
    imageSrc: "/img/imagesrc/agriculture.jpeg",
    title:
      "Technowell’s Cutting-Edge Research: Transforming Agriculture with AI and Machine Learning",
    content:
      "In the dynamic landscape of agriculture, Technowell emerges as a beacon of innovation, leading the charge in harnessing the power of Artificial Intelligence (AI) and Machine Learning (ML). Their groundbreaking research is reshaping how farmers cultivate crops, monitor fields, and predict yields, bringing unprecedented precision to every aspect of agriculture.",
    to: "/research/agriculturewithaiandml",
  },

  {
    imageSrc: "/img/imagesrc/AR.jpeg",
    title: "Research in AR and VR for Utilities and Fault Detection",
    content:
      "In the ever-evolving landscape of technology, Technowell stands at the forefront of innovation, particularly in the realm of Augmented Reality (AR) and Virtual Reality (VR). Their groundbreaking research is reshaping how utilities are managed and faults are detected, ushering in a new era of efficiency and precision.",

    to: "/research/arandvr",
  },
];

function Research() {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };

  const researchItemStyle = {
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    margin: "0 auto",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    height: "100%",
    width: "90%",
  };

  const contentStyle = {
    background: "#f9fafb",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
    padding: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  };

  const readMoreStyle = {
    display: "flex",
    fontFamily: "Poppins",
    cursor: "pointer",
    color: "#2eb67d",
    justifyContent: "flex-start",
  };

  const titleStyle = {
    color: "var(--e-global-color-f5fd46d)",
    fontFamily: "Poppins",
    fontWeight: 600,
    textDecoration: "none",
    marginBottom: "10px",
    marginTop: "auto",
  };

  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Research
          </p>
        </div>
      </div>

      <div style={researchItemStyle}>
        {researchData.map((item, index) => (
          <div key={index} style={cardStyle} onClick={() => navigate(item.to)}>
            <img
              src={item.imageSrc}
              style={{
                width: "100%",
                objectFit: "cover",
                height: "200px",
                filter: "brightness(80%)",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                cursor: "pointer",
              }}
            />
            <div style={contentStyle} onClick={() => navigate(item.to)}>
              <h3 style={titleStyle}>{item.title}</h3>
              <p style={{ fontWeight: 400, fontFamily: "Poppins" }}>
                {item.content}
              </p>
              <div style={readMoreStyle}>Read more</div>
            </div>
          </div>
        ))}
      </div>

      <div style={{ marginBottom: "100px" }}></div>

      <Footer />
    </div>
  );
}

export default Research;

import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  const notFoundStyle = {
    textAlign: 'center',
    marginTop: '100px',
    fontFamily: 'Arial, sans-serif',
  };

  const headingStyle = {
    fontSize: '4rem',
    color: '#FF5733',
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    marginTop: '20px',
  };

  return (
    <div style={notFoundStyle}>
      <h1 style={headingStyle}>404 - Page Not Found</h1>
      <p style={paragraphStyle}>Sorry, the page you are looking for does not exist.</p>
      <button style={{backgroundColor:"#1f5d7d",border:"none",padding:"15px",borderRadius:"10px",cursor:"pointer"}} ><Link to="/" style={{textDecoration:"none",color:"white",fontWeight:"600"}}> 
      Redirect to Homepage
      </Link>
      </button>
    </div>
  );
}

export default NotFound;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return (
    <div>
      <div className="footer">
        <div className="footerflex">
          <div className="footericons">
            <div className="footer1">
              <a href="/">
                <img
                  src="/img/logo-f.png"
                  className="logoimgfooter"
                  alt="logotechnowell"
                />
              </a>
            </div>

            <div className="icons">
              <a href="/">
                <img
                  src="/icons/facebookicon.svg"
                  alt="facebookicon"
                  className="iconsfooter"
                />
              </a>
              <a href="/">
                <img
                  src="/icons/instagramicon.svg"
                  alt="instagramicon"
                  className="iconsfooter"
                />
              </a>
              <a href="/">
                <img
                  src="/icons/twittericon.svg"
                  alt="twittericon"
                  className="iconsfooter"
                />
              </a>
              <a href="/">
                <img
                  src="/icons/youtubeicon.svg"
                  alt="youtubeicon"
                  className="iconsfooter"
                />
              </a>
            </div>
          </div>
          <div className="footerheading">
            <div className="footerheading1">
              <ul className="ulfooter">
                <h3>Quick Links</h3>
                <li>
                  <Link to="/research">Research</Link>
                </li>
                <li>
                  <Link to="/industry">Industry</Link>
                </li>

                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="footerheading2">
              <ul className="ulfooter">
                <h3>Industry</h3>
                <li>
                  <Link to={"/industry/educationaltechnologies"}>
                    Education Technologies
                  </Link>
                </li>
                <li>
                  <Link to={"/industry/energyresourcesandutilities"}>
                    Energy Resources and Utilities
                  </Link>
                </li>

                <li>
                  <Link to={"/industry/pharma"}>Pharma</Link>{" "}
                </li>
                <li>
                  <Link to={"/industry/publicsector"}>Public Sector</Link>
                </li>
              </ul>
            </div>
            <div className="footerheading2">
              <ul className="ulfooter">
                <h3>Services</h3>
                <li>
                  <Link to={"/services/consulting"}>Consulting</Link>
                </li>
                <li>
                  <Link to={"/services/staffing"}> Staffing</Link>
                </li>
                <li>
                  <Link to={"/services/cad"}>CAD </Link>
                </li>
                <li>
                  <Link to={"/services/geosptialservices"}>
                    {" "}
                    Geo Spatial Services
                  </Link>
                </li>
                <li>
                  <Link to={"/services/marketresearch"}> Market Research</Link>
                </li>
                <li>
                  <Link to={"/services/enterpriseresourceapplication"}> Enterprise Resource Application</Link>
                </li>
              </ul>
            </div>
            {/* <div className="footerheading2">
              <ul className="ulfooter">
                <h3>Products</h3>
                <li>
                  <Link to={"/products/elearningplatform"}>
                    {" "}
                    E learning Platform
                  </Link>
                </li>
                <li>
                  <Link to={"/products/dms"}>Document Management System</Link>
                </li>
                <li>
                  <Link to={"/products/ams"}>Asset Management System </Link>
                </li>
              </ul>
            </div> */}
            <div className="footerheading3">
              <ul className="ulfooter">
                <h3>Address</h3>
                <li>HIG-B-174,APHB Colony,Phase-IV,</li>
                <li>Gachibowli,Hyderbad,Telangana 500032 </li>
                <li>+91-75693 56171</li>
                <li>info@technowell.org.in</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="reserve">
        <span>2013 </span>
        <span>
          <a href="/" className="footer2">
            Technowell
          </a>
        </span>
        <span>- All rights reserved</span>
      </div>
    </div>
  );
};

export default Footer;

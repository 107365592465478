import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import MenuTab from "../MenuTab";
function Homepage1() {
  const [items, setItems] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [itemActive, setItemActive] = useState(2);
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "-90px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  useEffect(() => {
    const items = document.querySelectorAll(".slider .list .item");
    const thumbnails = document.querySelectorAll(".thumbnail .item");

    setItems(Array.from(items));
    setThumbnails(Array.from(thumbnails));
  }, []);

  useEffect(() => {
    // Automatically switch to the next item every 8000 milliseconds
    const intervalId = setInterval(() => {
      handleNextClick();
    }, 7000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [itemActive]);

  useLayoutEffect(() => {
    showSlider();
  }, [items, itemActive]);

  const showSlider = () => {
    const itemActiveOld = document.querySelector(".slider .list .item.active");
    const thumbnailActiveOld = document.querySelector(
      ".thumbnail .item.active"
    );

    if (itemActiveOld && thumbnailActiveOld) {
      itemActiveOld.classList.remove("active");
      thumbnailActiveOld.classList.remove("active");
    }

    if (items[itemActive] && thumbnails[itemActive]) {
      items[itemActive].classList.add("active");
      thumbnails[itemActive].classList.add("active");
    }
  };

  const handleNextClick = () => {
    let nextItemActive = itemActive + 1;
    if (nextItemActive >= items.length) {
      nextItemActive = 0;
    }
    setItemActive(nextItemActive);
  };

  const handlePrevClick = () => {
    let prevItemActive = itemActive - 1;
    if (prevItemActive < 0) {
      prevItemActive = items.length - 1;
    }
    setItemActive(prevItemActive);
  };

  useEffect(() => {}, []);
  const handleThumbnailClick = (index) => {
    setItemActive(index);
  };
  const sliderData = [
    {
      imgSrc: "img/imagesrc/agriculture.jpeg",
      title: "AI/ML in Agriculture ",
      description:
        "In the dynamic landscape of agriculture, Technowell emerges as a beacon of innovation, leading the charge in harnessing the power of Artificial Intelligence (AI) and Machine Learning (ML)",
      to: "/research/agriculturewithaiandml",
    },
    {
      imgSrc: "img/imagesrc/AR.jpeg",
      title: "Argumented Reality (AR) and Virtual Reality (VR)",
      description:
        "Explore the future with our Augumented Reality (AR) and Virtual Reality (VR) solutions, currently in active development for transformative user experiences",

      to: "/research/arandvr",
    },
    {
      imgSrc: "/img/imagesrc/WQ Home Page.jpeg",
      title: "AI/ML in Water Quality ",
      description:
        "Technowell's groundbreaking approach to water quality analysis involves the integration of AI and ML algorithms, transforming traditional lab testing methods",

      to: "/research/waterqualitywithaiandml",
    },
    {
      imgSrc: "/img/imagesrc/Techno BI Homepage.jpeg",
      title: "Techno BI ",
      description:
        "Techno BI is a revolutionary AI and machine learning-powered tool that empowers you to transform your data into stunning visuals and insightful reports. Whether you're an enterprise or an individual user, Techno BI provides you with the flexibility and scalability you need to make informed decisions and gain valuable insights from your data.",

      to: "/products/technobi",
    },
  ];

  return (
    <div className="headercontentarea">
      <div className="header-animation-area">
        <div style={navbarStyle}>
          <Headerpage />
        </div>
        <div>
          <Headerpage />
        </div>
        {/* <div style={{ width: "100%", justifyContent: "center",position:"relative",margin:"auto" ,display:"flex"}}>
          <MenuTab />
        </div> */}

        <div className="slider">
          <div className="list">
            {sliderData.map((sliderItem, index) => (
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
              >
                <img
                  src={sliderItem.imgSrc}
                  alt={sliderItem.title}
                  loading="lazy"
                />
                <div className="content" style={{}}>
                  <p> </p>

                  <h2 style={{ color: "#fff" }}>
                    {sliderItem.title}
                    <hr
                      className="hrlinecolors"
                      style={{
                        border: "2.9px solid #fff",
                      }}
                    ></hr>
                  </h2>
                  <p style={{ color: "#fff" }}>
                    {sliderItem.description}
                    <div
                      style={{
                        marginBottom: "100px",
                        justifyContent: "start",
                        width: "100%",
                        display: "flex",
                        marginTop: "15px",
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          color: "#155f82",
                          width: "150px",
                          borderRadius: "50px",
                          padding: "13px",
                          fontFamily: "Poppins",
                          display: "flex",
                          justifyContent: "center",
                          boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
                          marginRight: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(sliderItem.to)}
                      >
                        Know more
                        <img
                          src="/img/right-arrow copy.svg"
                          style={{ width: 20, marginLeft: "10px" }}
                        />
                      </button>
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="arrows">
            <button id="prev" onClick={handlePrevClick}>
              &lt;
            </button>
            {"  "}
            <button id="next" onClick={handleNextClick}>
              &gt;
            </button>
          </div>
          {/* <div className="thumbnail">
            {sliderData.map((thumbnail, index) => (
              
              <div
                key={index}
                className={`item ${index === itemActive ? "active" : ""}`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img src={thumbnail.imgSrc} alt={thumbnail.title} />
                    
                 {index===1&&
                     <img
                  src="img/img/ARVRCarouselBG.webp"
                  style={{
                    width: "50%",
                    height: "50%",
                    position: "absolute",
                    left: "20%",
                    top: "20%",
                    objectFit: "contain",
                  }}
                />}
                    {index===2&&
                     <img
                  src="img/img/Techno BI image.png"
                  style={{
                    width: "75%",
                    height: "95%",
                    position: "absolute",
                    left: "20%",
                    top: "15%",
                    objectFit: "contain",
                  }}
                />}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Homepage1;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../Header";
import { Fade } from "react-reveal";
import ScrollToTopButton from "../Scrollbutton";
import Footer from "../Footer";
export default function DocumentManagementSystem() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>
   
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/DMS.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3
          style={{ fontFamily: "Poppins", fontWeight: 700, color: "#155f82" }}
        >
          Document Management System (DMS)
        </h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          A centralized, digital platform designed to efficiently capture,
          store, organize, and retrieve documents. It streamlines document
          workflows, ensures version control, enhances collaboration, and
          promotes secure access. DMS improves overall efficiency, reduces paper
          usage, and facilitates compliance with document- related regulations.
        </p>
      </div>
      {/* <div
        style={{ display: "flex", width: "100%", height: "400px" }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Public Sector.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div> */}
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Key Features
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div>
              <p style={{ fontFamily: "Poppins" }}>
                • Document Capture and Storage
              </p>
              <p style={{ fontFamily: "Poppins" }}>• Version Control</p>
              <p style={{ fontFamily: "Poppins" }}>
                • Document Retrieval and Search
              </p>
              <p style={{ fontFamily: "Poppins" }}>
                • Access Control and Security
              </p>
              <p style={{ fontFamily: "Poppins" }}>• Collaboration Tools</p>
              <p style={{ fontFamily: "Poppins" }}>• Workflow Automation</p>
              <p style={{ fontFamily: "Poppins" }}>
                • Integration with Other Systems
              </p>
            </div>
            <div>
              <p style={{ fontFamily: "Poppins" }}>
                • Document Capture and Storage
              </p>
              <p style={{ fontFamily: "Poppins" }}>• Version Control</p>
              <p style={{ fontFamily: "Poppins" }}>
                • Document Retrieval and Search
              </p>
              <p style={{ fontFamily: "Poppins" }}>
                • Access Control and Security
              </p>
              <p style={{ fontFamily: "Poppins" }}>• Collaboration Tools</p>
              <p style={{ fontFamily: "Poppins" }}>• Workflow Automation</p>
              <p style={{ fontFamily: "Poppins" }}>
                • Integration with Other Systems
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          marginLeft: "-10%",
          marginBottom: "50px",
        }}
      >
        {/* <p style={{ fontFamily: "Poppins" }}>Want to know more?</p> */}
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "180px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px black",
            height: "40px",
            marginLeft: "4%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

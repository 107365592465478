import React, { useState, useEffect } from "react";
import Headerpage from "../Header";
import Fade from "react-reveal/Fade";
import { useLocation } from "react-router-dom";

function Aboutpage1() {
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <Fade top>
        <div style={navbarStyle}>
          <Headerpage />
        </div>
      </Fade>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              textAlign: "start",
              marginTop: "90px",
              marginLeft: "20px",
              fontSize: "2.2rem",
              color: "#fff",
              fontWeight: 600,
              fontFamily: "Poppins",
            }}
          >
            About us
          </p>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
          }}
          className="missionstatement"
        >
          <p
            style={{
              textAlign: "center",
              color: "#155f82",
              fontWeight: 600,
              fontSize: "1.2rem",
              fontFamily: "Poppins",
            }}
          >
            Mission Statement
          </p>
          <p
            style={{
              textAlign: "justify",
              color: "black",

              fontSize: "1rem",
              fontFamily: "Poppins",
            }}
          >
            Empowering progress through innovative technology solutions,
            Technowell Enterprise Services is dedicated to creating products and
            services that not only meet but exceed the evolving needs of our
            clients and contribute significantly to societal advancement. We are
            committed to being a catalyst for positive change, providing
            transformative solutions that make a lasting impact.{" "}
          </p>
        </div>
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
          }}
          className="missionstatement"
        >
          <p
            style={{
              textAlign: "center",
              color: "#155f82",
              fontWeight: 600,
              fontSize: "1.2rem",
              fontFamily: "Poppins",
            }}
          >
            Vision Statement
          </p>
          <p
            style={{
              textAlign: "justify",
              color: "black",
              fontSize: "1rem",
              fontFamily: "Poppins",
            }}
          >
            At Technowell, we envision a future where technology seamlessly
            integrates with human needs, enriching lives and driving progress.
            We strive to be a globally recognized leader, not just in delivering
            exceptional IT services, but in shaping a tech-enabled world that
            fosters growth, inclusivity, and sustainable development. Our vision
            is to pioneer innovation, setting new standards that redefine
            possibilities and leave a lasting legacy for generations to come..{" "}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          marginBottom: "100px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            color: "#155f82",
            fontWeight: 600,
            fontSize: "1.2rem",
          }}
        >
          Management and Governance
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
          className="director"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
            className="management"
          >
            <div
              style={{
                color: "#fff",
                background: "#155f82",
                width: "200px",
                height: "50px",
                zIndex: 999,
                marginTop: "4px",
                borderRadius: "10px",
                position: "relative",
                left: "50px",
              }}
            >
              <p>Managing Director</p>
            </div>

            <div
              style={{
                background: "#fff",
                width: "300px",
                border: "2px solid #155f82",
              }}
            >
              <p>Vc Ananthan</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                color: "#fff",
                background: "#155f82",
                width: "200px",
                height: "50px",
                zIndex: 999,
                marginTop: "4px",
                borderRadius: "10px",
                position: "relative",
                left: "50px",
              }}
            >
              <p>Director</p>
            </div>
            <div
              style={{
                background: "#fff",
                width: "300px",
                border: "2px solid #155f82",
              }}
            >
              <p>TSSV Lakshmi Narayana</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
          className="director"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                color: "#fff",
                background: "#155f82",
                width: "200px",
                height: "50px",
                zIndex: 999,
                marginTop: "4px",
                borderRadius: "10px",
                position: "relative",
                left: "50px",
              }}
            >
              <p>Financial Director</p>
            </div>
            <div
              style={{
                background: "#fff",
                width: "300px",
                border: "2px solid #155f82",
              }}
            >
              <p>K Padmaja</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <div
              className="director"
              style={{
                color: "#fff",
                background: "#155f82",
                width: "200px",
                height: "50px",
                zIndex: 999,
                marginTop: "4px",
                borderRadius: "10px",
                position: "relative",
                left: "50px",
              }}
            >
              <p>Executive Director</p>
            </div>
            <div
              style={{
                background: "#fff",
                width: "300px",
                border: "2px solid #155f82",
              }}
            >
              <p>Sanjay Kumar Rai</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginBottom: "8%",
        }}
      >
        <img
          src="img/img/alliances.jpg"
          style={{ width: "55%" }}
          className="alliancesimg"
        />
      </div>
      <div
        style={{ width: "100%", justifyContent: "center", textAlign: "center" }}
      >
        <h3
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
            marginBottom: "50px",
          }}
        >
          Here are our core values and what they mean to the client...
        </h3>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className="clientdesc"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontWeight: 400,
              textAlign: "start",
            }}
          >
            <b>We are results oriented.</b> We make it happen. We do not promise
            what we cannot deliver. What we do promise, we do everything in our
            power to deliver on time and at the agreed terms.
          </p>
        </div>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className="clientdesc"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontWeight: 400,
              textAlign: "start",
            }}
          >
            <b>We deliver value.</b> We protect the client's investment in our
            products by making certain that they work as expected and are not
            overpriced.
          </p>
        </div>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className="clientdesc"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontWeight: 400,
              textAlign: "start",
            }}
          >
            <b>We think ahead and communicate openly.</b> We listen to client
            ideas, challenges and successes. We try to incorporate as much as
            possible from your suggestions into each new version of our
            products. We want the client's experience with our products to be
            exceptional.
          </p>
        </div>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className="clientdesc"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontWeight: 400,
              textAlign: "start",
            }}
          >
            <b>We possess integrity and honesty.</b> We always deal fairly and
            honestly with clients. We do not sell our products if they do not
            meet the client's needs.
          </p>
        </div>
        <div
          style={{
            width: "60%",
            flexDirection: "row",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className="clientdesc"
        >
          <p
            style={{
              fontFamily: "Poppins",
              color: "black",
              fontWeight: 400,
              textAlign: "start",
            }}
          >
            <b>We have commitment.</b> Our commitment is to continually improve
            our products and exceed client's expectations.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Aboutpage1;

import React, { useEffect, useState } from "react";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
import Headerpage from "../../Header";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
export default function PublicSector() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
       <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>
     
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Public Sector.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>
          Public Sector
        </h3>
      </div>
      <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Software enhancement and development in the public sector are vital
          for streamlined operations, improved service delivery, and enhanced
          citizen engagement. Up-to-date and efficient software systems enable
          government agencies to adapt to evolving needs, ensure data security,
          and foster innovation, ultimately contributing to a more responsive
          and effective public administration.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          E Governance System
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>
            Technowell's e-Governance system signifies a transformative leap in
            public administration. This software solution fosters transparency,
            efficiency, and citizen engagement. By digitizing processes,
            optimizing workflows, and ensuring secure data handling,
            Technowell's e- Governance system empowers governments to provide
            accessible, responsive, and modern services to citizens, advancing
            the principles of good governance.
          </p>

          {/* <button
            style={{
              border: "2px solid #155f82",
              color: "#155f82",
              width: "150px",
              borderRadius: "50px",
              padding: "10px",
              fontFamily: "Poppins",
              display: "flex",
              justifyContent: "center",
              boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            }}
          >
            Learn more
            <img
              src="/img/right-arrow copy.svg"
              style={{ width: 20, marginLeft: "10px" }}
            />
          </button> */}
        </div>
      </div>
      <ScrollToTopButton />

      <Footer />
    </div>
  );
}

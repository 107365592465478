import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Headerpage from "../components/Header";

function Products() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  const IndustryCard = React.memo(({ imageSrc, title, to }) => {
    return (
      <div className="induistryminicard">
        <NavLink to={to} className="nav-links">
          <img
            src={imageSrc}
            alt={title}
            className="industry-image"
            loading="lazy"
          />
          <p className="industry-title">{title}</p>
        </NavLink>
      </div>
    );
  });
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>

      <div className="about-hero-banner">
        <div className="about-hero-text">
          <p
            style={{
              fontFamily: "Poppins",
              fontWeight: 500,
              marginLeft: "2%",
              fontSize: "1.3rem",
              color: "#fff",
              textAlign: "start",
              marginTop: "80px",
            }}
          >
            Learn More About Products
          </p>
        </div>
      </div>
      <Fade bottom>
        <div className="industrycards">
          <IndustryCard
            imageSrc="/img/imagesrc/elearning1.png"
            title="  eLearning Platform"
            to={"/products/elearningplatform"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/DMS.jpeg"
            title=" Document Management System (DMS)"
            to={"/products/dms"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Asset Management.jpeg"
            title="Asset Management System (AMS)"
            to={"/products/ams"}
          />
          <IndustryCard
            imageSrc="/img/imagesrc/Techno Bi inside.jpeg"
            title="Techno BI"
            to={"/products/technobi"}
          />
        </div>
      </Fade>
      <div style={{ marginBottom: "100px" }}></div>

      <Footer />
    </div>
  );
}

export default Products;

import React from "react";
import Aboutpage1 from "../components/About/Aboutpage1";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/Scrollbutton";

function Aboutus() {

  return (
    <div>
      <Aboutpage1 />
      <ScrollToTopButton/>
      <Footer />
    </div>
  );
}

export default Aboutus;

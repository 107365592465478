import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { useLocation, useNavigate } from "react-router-dom";
import Headerpage from "../Header";
import ScrollToTopButton from "../Scrollbutton";
import Footer from "../Footer";

export default function EnterpriseResourceApplication() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />
          </div>
        </Fade>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/Enterprise Resource Mgmt.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>Enterprise Resource Application Development </h3>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Technowell, a dynamic player in Enterprise Resource Application
          Development, is dedicated to delivering versatile solutions for
          businesses. Specializing in online and offline mobile tools,
          Technowell's expertise lies in crafting customized applications that
          enhance operational efficiency. With a commitment to innovation, the
          company ensures uninterrupted access to critical resources, featuring
          intuitive interfaces, real-time data synchronization, and robust
          security measures. As businesses evolve, Technowell remains a reliable
          partner, providing high-quality solutions for the demands of the
          modern digital landscape. For the latest information, visit their
          official website or contact customer support.
        </p>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          Technowell exemplifies its expertise in creating impactful solutions
          through successful collaborations, such as its partnership with MFI
          Document Solutions in Uganda for the Water and Environment Ministry.
          Together, they developed a comprehensive Water and Information system,
          showcasing Technowell's commitment to addressing critical challenges
          in resource management. Additionally, Technowell has made significant
          contributions in India by creating cutting-edge geospatial online and
          offline tools. These tools facilitate the monitoring and maintenance
          of over 20 Urban Local Bodies in Uttar Pradesh, underscoring
          Technowell's dedication to innovative solutions that drive efficiency
          and sustainability in diverse environments.
        </p>
      </div>
   
      <div
        style={{
          marginBottom: "100px",
          justifyContent: "end",
          width: "100%",
          display: "flex",
        }}
      >
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "150px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

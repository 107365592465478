import React, { Suspense } from "react";
import Homepage1 from "../components/home/Homepage1";
import Homepage2 from "../components/home/Homepage2";
import Homepage3 from "../components/home/Homepage3";
import ScrollToTopButton from "../components/Scrollbutton";
import Footer from "../components/Footer";
import Homepage4 from "../components/home/Homepage4";
import Homepage5 from "../components/home/Homepage5";

function Home() {
  return (
    <Suspense fallback={<div></div>}>
      <Homepage1 />
      <Homepage2 />
    
      <ScrollToTopButton />

      <Footer />
    </Suspense>
  );
}
export default Home;

import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PulseLoader from "react-spinners/PuffLoader";
import "./App.css";
import EducationalTech from "./components/Industry/Educationtech/EducationalTech";
import EnergyResources from "./components/Industry/EnergyResourcesandutilities/EnergyResources";
import Pharma from "./components/Industry/Pharma/Pharma";
import PublicSector from "./components/Industry/PublicSector/PublicSector";
import NotFound from "./components/NotFound";
import AssetManagement from "./components/Products/AssetManagementSystem";
import DocumentManagementSystem from "./components/Products/DocumentManagementsystem";
import Elearningplatform from "./components/Products/Elearningplatform";
import Cad from "./components/services/Cad/Cad";
import Consulting from "./components/services/Consulting/Consulting";
import MobileResearch from "./components/services/MarketResearch/MarketResearchApplication";
import Staffing from "./components/services/Staffing/Staffing";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Home from "./pages/Home";
import Industry from "./pages/Industry";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Geopsatialservices from "./components/services/Geospatial/Geospatialservices";
import Research from "./pages/Research";
import AgricultureAiMl from "./components/Research/AgricultureAiMl";
import WaterQualityAiMl from "./components/Research/WaterQualityAiMl";
import ARandVR from "./components/Research/ARandVR";
import EnterpriseResourceApplication from "./components/services/EnterpriseResourceApplication";
import TechnoBI from "./components/Products/TechnoBi";

function App() {
  return (
    <div>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "40%",
              left: "45%",
              // transform: "translate(-50%,-50%)"
            }}
          >
            <PulseLoader color="#36d7b7" />{" "}
            <span style={{ color: "white" }}>loading</span>
          </div>
        }
      >
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/services" element={<Services />} />
            <Route path="/research" element={<Research />} />
            <Route
              path="/research/agriculturewithaiandml"
              element={<AgricultureAiMl />}
            />
            <Route
              path="/research/waterqualitywithaiandml"
              element={<WaterQualityAiMl />}
            />
            <Route path="/research/arandvr" element={<ARandVR />} />

            <Route path="/industry" element={<Industry />} />
            <Route
              path="/industry/educationaltechnologies"
              element={<EducationalTech />}
            />
            <Route
              path="/industry/energyresourcesandutilities"
              element={<EnergyResources />}
            />

            <Route path="/industry/pharma" element={<Pharma />} />
            <Route path="/industry/publicsector" element={<PublicSector />} />
            <Route path="/services/consulting" element={<Consulting />} />

            <Route path="/services/staffing" element={<Staffing />} />
            <Route path="/services/cad" element={<Cad />} />
            <Route
              path="/services/geosptialservices"
              element={<Geopsatialservices />}
            />
            <Route
              path="/services/marketresearch"
              element={<MobileResearch />}
            />
            <Route
              path="/services/enterpriseresourceapplication"
              element={<EnterpriseResourceApplication />}
            />

            <Route path="/products" element={<Products />} />
            <Route
              path="/products/elearningplatform"
              element={<Elearningplatform />}
            />
            <Route
              path="/products/dms"
              element={<DocumentManagementSystem />}
            />
            <Route path="/products/ams" element={<AssetManagement />} />
            <Route path="/products/technobi" element={<TechnoBI />} />

            <Route path="/contactus" element={<Contactus />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;

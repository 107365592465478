import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import Headerpage from "../../Header";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTopButton from "../../Scrollbutton";
import Footer from "../../Footer";
export default function Cad() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    top: showNavbar ? "0" : "0px",
    transition: "top 0.9s ease-in-out",
    position: "fixed",
    width: "100%",
    background: "#fff",
    zIndex: "1000",
    height: "75px",
    boxShadow: "0px 1px 2px rgb(181, 185, 181)",
  };
  return (
    <div>
      <div className="topnav">
        <Fade top>
          <div style={navbarStyle}>
            <Headerpage />

          </div>
        </Fade>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "400px",
          marginTop: "77px",
        }}
        className="elarnimgcard"
      >
        <img
          src="/img/imagesrc/CAD.jpeg"
          style={{
            display: "flex",
            width: "100%",
            display: "block",
            objectFit: "cover",
          }}
          className="elaernlogo"
        />
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h3 style={{ fontFamily: "Poppins", fontWeight: 700 }}>CAD</h3>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          We have technology partners that we team up with to provide on time,
          high quality CAD Conversion Services. We regularly explore, evaluate
          and adapt new technologies & strategies to help our Clients reduce
          costs, optimize time and increase profitability. We can offer fixed
          cost pricing or dedicated FTE team model.
        </p>
        <p
          style={{
            fontSize: "16px",
            fontWeight: 400,
            width: "60%",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
          className="educationdesc"
        >
          We provide CAD Drafting services (Equipment, Piping, Civil,
          Structural, Architectural, Electrical etc), 3D Model Visualization,
          CAD Conversion services, Site Plan and Real Estate Floor Plan
          development and drawings. We believe in working closely with clients
          to understand their unique requirements and specialize in providing
          customized business solutions.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: "20px",
          marginBottom: "50px",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="elearncard"
      >
        <p
          style={{
            fontFamily: "Poppins",
            color: "#155f82",
            fontWeight: 600,
          }}
        >
          Our Tailored CAD Conversion Services include:
        </p>
        <div
          style={{
            fontFamily: "Poppins",
            width: "50%",
            marginLeft: "2%",
            flexDirection: "column",
          }}
          className="elearndesc"
        >
          <p style={{ fontFamily: "Poppins" }}>
            • Paper to CAD Conversion (converting legible Board drawings to CAD
            compatible file)
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • PDF to CAD Conversion (converting legible PDF drawings to CAD
            compatible file)
          </p>
          <p style={{ fontFamily: "Poppins" }}>
            • Image to CAD Conversion (converting high resolution JPEG drawings
            to CAD compatible file)
          </p>
        </div>
      </div>{" "}
      <div
        style={{
          marginBottom: "100px",
          justifyContent: "end",
          width: "100%",
          display: "flex",
        }}
      >
        <button
          style={{
            border: "2px solid #155f82",
            color: "#155f82",
            width: "150px",
            borderRadius: "50px",
            padding: "10px",
            fontFamily: "Poppins",
            display: "flex",
            justifyContent: "center",
            boxShadow: "5px 5px 10px rgba((255, 255, 255,1)",
            marginRight: "10%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/contactus")}
        >
          Contact us
          <img
            src="/img/right-arrow copy.svg"
            style={{ width: 20, marginLeft: "10px" }}
          />
        </button>
      </div>
      <ScrollToTopButton />
      <Footer />
    </div>
  );
}

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
const Privatekey=process.env.REACT_APP_PUBLIC_KEY
const serviceid= process.env.REACT_APP_SERVICE_ID
const templeid=process.env.REACT_APP_TEMPLATE_ID

function Contactus1() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        serviceid,
      templeid,
        form.current,
        Privatekey
      )
      .then(
        (result) => {
          alert("Message sent successfully");
          e.target.reset();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div className="contact-hero-banner">
        <div className="about-hero-text">
          <h1     style={{
              textAlign: "start",
              marginTop: "72px",
              marginLeft: "20px",
            }}>Contact Us</h1>
        </div>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
        className="emailmedia"
      >
        <div className="contactemail">
          <div>
            <img src="img/email.svg" className="emailimg" alt="email logo" />
            <h3>Mail Address</h3>
            <span>info@technowell.org.in</span>
          </div>
        </div>
        <div className="contactemail">
          <div>
            <img
              src="img/smartphone.svg"
              className="emailimg"
              alt="phone logo"
            />
            <h3>Our Phone</h3>
            <span>+91-75693 56171</span>
          </div>
        </div>
        <div className="contactemail">
          <div>
            <img src="img/pin.svg" className="emailimg" alt="location logo" />
            <h3>Our Location</h3>
            <span>HIG B 174, APHB Colony, Gachibowli, Hyderabad-500032.</span>
          </div>
        </div>
      </div>
      <div className="emailcard">
        <div className="emailform">
          <h1 style={{ color: "#323657" }}>Leave a Message</h1>

          <form ref={form} onSubmit={sendEmail}>
            <div className="formdata" style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="text"
                name="user_name"
                className="inputcss"
                placeholder=" Enter Your Name"
                required
              />

              <input
                type="email"
                name="user_email"
                className="inputcss"
                placeholder="Enter Your Email"
                required
              />
            </div>
            <div className="formdata" style={{ display: "flex", flexDirection: "row", }}>
              <input
                type="number"
                name="user_mobilenumber"
                className="inputcss"
                placeholder="Enter Your Mobile Number"
                required
              />

              <input
                type="text"
                name="user_location"
                className="inputcss"
                placeholder="Enter Your Location"
                required
              />
            </div>
            <textarea
              name="message"
              className="inputcss"
              placeholder=" Enter Your Message"
              required
            />
            <input type="submit" value="Submit" className="submitbutton" />
          </form>
        </div>

        <div style={{width:"50%",marginLeft:"10%"}}>
          <iframe
            className="mapscss"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60900.17142367177!2d78.2880274216797!3d17.447231300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93bf735b9111%3A0xcd84fd8aa21ca083!2sTechnowell%20Enterprise%20Service%20Private%20ltd!5e0!3m2!1sen!2sin!4v1696842549496!5m2!1sen!2sin"
            width="450"
            height="450"
            style={{ border: "0", borderRadius: "20px" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contactus1;
